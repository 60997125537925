import {type CategoryDetailsTypes} from 'utils/TypeConfig';

export const renderCategoryOptions = (
  data: CategoryDetailsTypes,
  initialValues: CategoryDetailsTypes[] = [], // set a default value
  categoryName: string[] = [],
  editCategory: string = '',
): string => {
  const parentData: CategoryDetailsTypes | undefined = initialValues?.find(
    innerData => innerData?.id === data?.parent_id,
  );
  const categoryArray = [...categoryName, data.name];

  // If the current category matches the editCategory
  if (editCategory === data.name) {
    // If the matched category is at the top level (root), return nothing
    if (data.id === data.parent_id) {
      return ''; // Do not render anything for top-level match
    }
    // Otherwise, return parent categories (excluding the matched category)
    return categoryName.join(' > ');
  }

  if (data.id === data.parent_id) {
    const categoryOption = data.name;
    return categoryOption;
  }

  console.log('🚀 ~ parentData:', parentData);
  if (parentData != null) {
    return renderCategoryOptions(
      parentData,
      initialValues,
      categoryArray,
      editCategory,
    );
  } else {
    console.log('🚀 ~ parentData: child', categoryArray);
    const categoryOption = categoryArray.reverse().join(' > ');
    return categoryOption;
  }
};
