const apiEndpoints = {
  LOGIN: '/api/admin/login',
  VERIFY_OTP: '/api/admin/verify-otp',
  FORGET_PASSWORD: '/api/admin/send-reset-link',
  RESET_PASSWORD: '/api/admin/reset-password',
  GET_OPTIONS_LIST: '/api/common/get-option',
  CREATE_OPTION: '/api/common/create-option',
  UPDATE_OPTION: '/api/common/update-option',
  CREATE_OPTION_VALUE: '/api/common/create-option-value',
  UPDATE_OPTION_VALUE: '/api/common/update-option-value',
  DELETE_OPTION_VALUE: '/api/common/delete-option-value',
  DELETE_OPTION: 'api/common/delete-option',
  CREATE_PRODUCT: '/api/common/create-product',
  UPDATE_PRODUCT: '/api/common/update-product',
  GET_PRODUCT: '/api/common/get-product',
  GET_OPTION_VALUE_BY_ID: '/api/common/get-option-value?type=Dropdown',
  GET_OPTIONS_BY_ID: '/api/common/get-option',
  CREATE_PRODUCT_OPTION: '/api/common/create-product-option',
  UPDATE_PRODUCT_OPTION: '/api/common/update-product-option',
  DELETE_PRODUCT_OPTION: '/api/common/delete-product-option-value',
  GET_GST_TAX: '/api/common/get-gst-tax?type=Dropdown',
  GET_CATEGORY: '/api/common/get-category?type=Dropdown',
  GET_OPTIONS: '/api/common/get-option?type=Dropdown',
  GET_OPTION_VALUE: '/api/common/get-option-value?type=Dropdown',
  GET_WEIGHT_LENGTH: '/api/common/get-weight-and-length',
  PRODUCT_LIST: '/api/common/get-product',
  DELETE_PRODUCT: '/api/common/delete-product',
  ADD_CATEGORY: 'api/common/create-category',
  GET_STATUS: 'api/common/get-status',
  UPDATE_CATEGORY: 'api/common/update-category',
  CATEGORY_LISTING: '/api/common/get-category',
  DELETE_CATEGORY: '/api/common/delete-category',
  CREATE_HSN: '/api/common/create-gst-tax',
  UPDATE_HSN: '/api/common/update-gst-tax',
  HSN_LISTING: '/api/common/get-gst-tax',
  HSN_DELETE: '/api/common/delete-gst-tax',
  GET_ORDERLIST: '/api/common/get-order',
  GET_PAYMENT_TRACKING: '/api/common/get-order-transaction',
  GET_STOCK: '/api/common/get-stock',
  GET_PRODUCT_BY_CATEGORY_ID: '/api/common/get-product-by-category-id',
  GET_OPTION_BY_PRODUCT_ID: '/api/common/get-option-by-product-id',
  GET_OPTION_VALUE_BY_OPTION_ID:
    '/api/common/get-option-value-by-pro-option-id',
  GET_COUPON_MEDIA_TYPE: '/api/common/get-coupon-and-media-type',
  CREATE_COUPON: '/api/common/create-coupon',
  UPDATE_COUPON: '/api/common/update-coupon',
  LIST_COUPON: '/api/common/list-coupon',
  DELETE_COUPON: '/api/common/delete-coupon',

  CREATE_BRAND: '/api/common/create-brand',
  UPDATE_BRAND: '/api/common/update-brand',
  LIST_BRAND: '/api/common/get-brand',
  DELETE_BRAND: '/api/common/delete-brand',
  GET_BRAND: '/api/common/get-brand?type=Dropdown',
  GET_BRAND_BY_CATEGORY: '/api/common/get-brand-by-category',
  GET_TEMPLATE: '/api/common/get-template',
  DELETE_TEMPLETE: '/api/common/delete-template',
  UPDATE_TEPLATE: '/api/common/update-template',
  CREATE_TEPLATE: '/api/common/create-template',

  UPDATE_ORDER_STATUS: '/api/common/update-order-status',
  GET_POLICY_LIST: '/api/common/get-policy',
  PRODUCT_LIST_BY_ID: 'api/common/get-product',
  GET_DASHBOARD_DATA: '/api/common/get-dashboard-data',
  GET_PROFILE: '/api/common/get-profile',
  UPDATE_PROFILE: '/api/warehouse/update-profile',
  GET_COUPON_ANALYSIS: '/api/common/coupon-analysis',
  ORDER_COUNT: '/api/common/order-count',
  GET_DELIVERY_PERSON: '/api/common/get-delivery-person',
  CREATE_DELIVERY_PERSON: '/api/common/create-delivery-person',
  UPDATE_DELIVERY_PERSON: '/api/common/update-delivery-person',
  DELETE_DELEVERY_PERSON: '/api/common/delete-delivery-person',
  GET_DISCOUNT: '/api/common/get-product-discount?sort_direction=asc',
  CREATE_DISCOUNT: '/api/common/create-product-discount',
  UPDATE_DISCOUNT: '/api/common/update-product-discount',
  DELETE_DISCOUNT: '/api/common/delete-product-discount',
  UPDATE_HEADER_STATUS: '/api/common/update-category-header-status',
  GET_SALES_REPORT: '/api/common/sales-report',
  ASSIGN_DELIVERY_PERSON: '/api/common/assign-delivery-person',
  UPDATE_PRODUCT_STATUS: '/api/common/update-product-status',
  PRICE_COMPARISION: '/api/common/get-flipkart-product',
  UPDATE_CATEGORY_STATUS: '/api/common/update-category-status',
  CANCEL_ORDER: '/api/common/cancel-order',
  DOWNLOAD_INVOICE: '/api/common/download-shiprocket-invoice',
  SHIPMENT_STATUS_TRACK: '/api/common/track-order',
  SHIP_ORDER_URL: '/api/common/ship-order-url',
};

export {apiEndpoints};
